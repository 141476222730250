














































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import * as _ from "lodash";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import {
  GetFilterOption,
  PostShujuchi,
  GetPatientData,
} from "@/request/differences";
import { GetCenter } from "@/request/common";
import { AddZhenci } from "@/request/storehouse";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import SearchDuoxuan from "@/components/search-duoxuan.vue";
import Jibenxinxi from "../components/jibenxinxi.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    SearchDuoxuan,
    Jibenxinxi,
  },
})
export default class Name extends mixins(listMixin) {
  private project_id: any = "";
  private jigous: any[] = [];
  private laiyuans: any[] = [];
  private patients: any = [];
  private ifShowJiben: any = false;
  private tempPatient: any = {};

  private get center() {
    return this.$store.state.center;
  }

  private jigouSearch(e: any) {
    this.getjigouList("机构", e);
  }
  private jigouChange(e: any) {
    this.filters["机构"] = e;
    this.filters.source = [];
    this.getjigouList("数据来源", "");
    this.search();
  }
  private laiyuanSearch(e: any) {
    this.getjigouList("数据来源", e);
  }
  private laiyuanChange(e: any) {
    this.filters.source = e;
    this.search();
  }
  /**
   * @description 添加诊次
   */
  private goAdd(patient: any) {
    const params: any = {
      project_id: this.center.project_id,
      group_id: this.center.group_id,
      patient_id: patient.patient_id,
      type: "1",
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    AddZhenci(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.$store.commit("updatePatient", patient);
        this.$store.commit("updateIfCompose", false);
        this.$router.push({
          path: "/main/storehouse/case/detail",
          query: {
            id: data.zhenci_id,
            source: "shujuchi",
          },
        });
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 打开基本信息弹窗
   */
  private openJibenxinxi(item: any) {
    const params = {
      params: {
        patient_id: item.patient_id,
      },
    };
    GetPatientData(this, params).then((res: any) => {
      this.tempPatient = res;
      this.ifShowJiben = true;
    });
  }
  private goDuijie(val: any, item: any) {
    this.$store.commit("updatePatient", item);
    this.$router.push({
      path: "/main/datacenter/pool/duijie",
      query: {
        type: val,
      },
    });
  }
  getList() {
    const source: any = [];
    if (this.filters.source && this.filters.source.length > 0) {
      this.filters.source.forEach((ele: any) => {
        source.push(ele._id);
      });
    }
    const jigou: any = [];
    if (this.filters["机构"] && this.filters["机构"].length > 0) {
      this.filters["机构"].forEach((ele: any) => {
        jigou.push(ele._id);
      });
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params = {
      project_id: this.project_id,
      institution_id: jigou,
      source,
      user_ids: this.filters.user_ids,
      order_field: this.filters.sort_field,
      order_value: this.filters.sort_value,
      current_page: this.filters.current_page,
      page_num: this.filters.page_count,
      search: this.filters.search,
    };
    localStorage.setItem("poolFilter", JSON.stringify(this.filters));
    PostShujuchi(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.patients = res.data;
        this.filters.total_page = res.total_page;
        this.filters.total_count = res.total_count;
        if (this.patients.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }

  private getjigouList(model: any, val: any) {
    const params: any = {
      type: model,
      search: val,
    };
    if (model === "数据来源") {
      const jigou: any = [];
      if (this.filters["机构"] && this.filters["机构"].length > 0) {
        this.filters["机构"].forEach((ele: any) => {
          jigou.push(ele._id);
        });
      }
      params.jigous = jigou;
    }
    GetFilterOption(this, params).then((res: any) => {
      if (model === "机构") {
        // 如果有已经选的，并且不在当前列表中，需要添加进去
        if (this.filters["机构"] && this.filters["机构"].length > 0) {
          this.filters["机构"].forEach((ele: any) => {
            const index = res.findIndex((value: any) => {
              return ele._id === value._id;
            });
            if (index !== -1) {
              res.splice(index, 1);
            }
          });
          this.jigous = this.filters["机构"].concat(res);
        } else {
          this.jigous = res;
        }
      } else if (model === "数据来源") {
        // 如果有已经选的，并且不在当前列表中，需要添加进去
        if (this.filters.source && this.filters.source.length > 0) {
          this.filters.source.forEach((ele: any) => {
            const index = res.findIndex((value: any) => {
              return ele._id === value._id;
            });
            if (index !== -1) {
              res.splice(index, 1);
            }
          });
          this.laiyuans = this.filters.source.concat(res);
        } else {
          this.laiyuans = res;
        }
      }
    });
  }
  /**
   * @description 获得专病数据库列表
   */
  private getCenterList() {
    GetCenter(this).then((data: any) => {
      this.$store.commit("updateCenter", data[0]);
      this.project_id = data[0].project_id;
      this.getList();
    });
  }
  /**
   * 初始化
   */
  mounted() {
    this.filters["机构"] = [];
    this.filters.source = [];
    this.filters.user_ids = [];
    this.filters.sort_field = "更新时间";
    const d = localStorage.getItem("poolFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getCenterList();
    this.getjigouList("机构", "");
    this.getjigouList("数据来源", "");
  }
}
